<template>
  <b-card>
    <div>
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm }"
        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
        :sort-options="{
          enabled: true,
        }"
      >
        <div
          slot="emptystate"
          class="text-center my-5"
        >
          <h3
            v-if="receipts.length>0"
          >
            Su búsqueda no coincide
          </h3>
          <b-overlay
            id="overlay-background"
            :show="show"
            rounded="lg"
            variant="bg-light"
            opacity="1.00"
            no-wrap
            no-fade
            class="mt-5"
          />
          <h3
            v-if="!show && receipts.length==0"
          >
            Aún no presenta facturas vigentes
          </h3>
        </div>
        <template
          slot="table-row"
          slot-scope="props"
        >
          <span v-if="props.column.field === 'Moneda'">
            {{ parseCurrency(props.row.Moneda) }}
          </span>
          <!-- Column: Status -->
          <span v-else-if="props.column.field === 'status'">
            <b-badge :variant="statusVariant(props.row.status)">
              {{ getStatus(props.row.status) }}
            </b-badge>
          </span>
          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
            <span>
              <b-button
                variant="primary"
                class="btn-icon"
                block
                :href="props.row.file"
                target="_blank"
              >
                <feather-icon icon="DownloadIcon" />
              </b-button>
            </span>
          </span>
          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                Mostrando grupos de
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['3','5','10']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap "> de {{ props.total }} registro(s) </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
      <b-row class="mt-3 mb-1 mx-1 flex-column">
        <strong class="my-0"> En esta sección puedes revisar tus documentos negociados en los últimos 6 meses y el estado de los mismos </strong>
        <strong class="mt-1"> (Vigente → Aún no vence; Cancelado → Ya fue pagado; Vencido → Tu cliente aún no lo ha pagado) </strong>
      </b-row>
    </div>
  </b-card>
</template>

<script>
/* eslint-disable global-require */
import {
  BBadge, BPagination, BFormSelect, BCard, BOverlay, BButton,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
// import useJwt from '@/auth/jwt/useJwt'
import store from '@/store/index'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    VueGoodTable,
    BOverlay,
    BBadge,
    BPagination,
    BFormSelect,
    BButton,
    BCard,
  },
  data() {
    return {
      show: true,
      userEmail: JSON.parse(localStorage.getItem('userData')).email,
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'Ruc Cliente',
          field: 'razonSocial',
        },
        {
          label: 'Documento',
          field: 'IdDocumento_Dsc',
        },
        {
          label: 'Mon',
          field: 'Moneda',
        },
        {
          label: 'Importe de Pago',
          field: 'ImportePago',
          tdClass: 'text-right',
          type: 'number',
          formatFn: this.moneyFormat,
        },
        {
          label: 'Fecha Desembolso',
          field: 'FechaDesembolso',
          tdClass: 'text-center',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd',
          formatFn: this.dateFormat,
        },
        {
          label: 'Estado',
          field: 'status',
          filterOptions: {
            enabled: true,
            placeholder: 'Buscar estado',
            filterDropdownItems: [
              { value: 'current', text: 'Vigente' },
              { value: 'canceled', text: 'Cancelado' },
              { value: 'overdue', text: 'Vencido' },
            ],
          },
        },
        {
          label: 'Días vencidos',
          field: 'DiasVenc',
          type: 'number',
        },
        {
          label: 'TEA',
          field: 'TasaAnual',
          type: 'number',
        },
        {
          label: 'Descargar liquidación',
          field: 'action',
        },
      ],
      rows: [],
      receipts: [],
      searchTerm: '',
      filterDropdownItems: [
        { value: 'n', text: 'Inactive' },
        { value: 'y', text: 'Active' },
        { value: 'c', text: 'Check' },
      ],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        current      : 'light-success',
        canceled : 'light-primary',
        overdue     : 'light-danger',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  async created() {
    await this.getReceipts()
  },
  methods: {
    filterFn(data, filterString) {
      const x = parseInt(filterString, 10)
      return data >= x - 5 && data <= x + 5
    },
    async getReceipts() {
      try {
        const userData = JSON.parse(localStorage.getItem('userData'))
        const { efideData: { clientId } } = userData
        const monthDiff = (d1, d2) => {
          let months
          months = (d2.getFullYear() - d1.getFullYear()) * 12
          months -= d1.getMonth()
          months += d2.getMonth()
          return months
        }
        const today = new Date()
        const { data: { receiptsPortfolio } } = await useJwt.getCurrentReceiptsPortfolio({ clientId })
        const values = receiptsPortfolio
        for (let i = 0; i < values.length; i += 1) {
          values[i].FechaDesembolso = values[i].FechaDesembolso.toString().replace(/\s/g, '').trim().split('/')
            .reverse()
            .join('-')
          values[i].cxpFechaCan_Dsc = values[i].cxpFechaCan_Dsc.toString().replace(/\s/g, '').trim().split('/')
            .reverse()
            .join('-')
          values[i].FechaVenc = values[i].FechaVenc.toString().replace(/\s/g, '').trim().split('/')
            .reverse()
            .join('-')
          const differenceDay = Math.floor((new Date(values[i].FechaVenc) - today) / (1000 * 3600 * 24))
          let status = 'current'
          if (values[i].cxpFechaCan_Dsc || values[i].cxpFechaCan_Dsc !== '') {
            status = 'canceled'
          } else if (differenceDay < 0) {
            status = 'overdue'
          }
          values[i].status = status
          values[i].file = `https://online.efide.pe/FilesEF/Liquidaciones/${values[i].IdLote}.pdf`
        }
        this.rows = values.filter(
          item => monthDiff(new Date(item.FechaVenc), today) <= 6,
        )
        this.receipts = this.rows
        this.show = false
      } catch (error) {
        console.log(error)
        this.show = false
      }
    },
    moneyFormat(money) {
      return !Number.isNaN(Number.parseFloat(money)) ? Number.parseFloat(money).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '-'
    },
    dateFormat(value) {
      return value.split('-').reverse().join('/')
    },
    getStatus(status) {
      let label = ''
      switch (status) {
        case 'current':
          label = 'Vigente'
          break
        case 'canceled':
          label = 'Cancelado'
          break
        case 'overdue':
          label = 'Vencido'
          break
        default:
          break
      }

      return label
    },
    parseCurrency(currency) {
      switch (currency) {
        case 'US$':
          return 'USD'
        case 'S/':
          return 'PEN'
        default:
          return '-'
      }
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.dark-layout {
  div ::v-deep .card .card-body {
      .b-overlay {
        .bg-light {
          background-color: $theme-dark-body-bg !important;
        }
      }
  }
}
</style>
